import { apiClient } from '../index';

/**
 * [Crea una reservación]
 * @return {[type]}          [description]
 */
function create({ title, description, reservationDate, reservationHour, lapse, partyRoomId }) {
  return apiClient.post('/v1/reservations/user', {
    title,
    description,
    reservationDate,
    reservationHour,
    lapse,
    partyRoomId,
  });
}

/**
 * [Rechaza una reservación.]
 * @param {number} id Identificador de la reservación
 * @return {[type]}          [description]
 */
 function cancel(id) {
  return apiClient.patch(`/v1/reservations/${id}/cancel`);
}

/**
 * [Obtiene las reservaciones según los criterios de búsqueda]
 * @return {[type]}          [description]
 */
function search(reservationId, customerId, status) {
  return apiClient.get('/v1/reservations/search', {
    params: {
      reservationId,
      customerId,
      status,
    },
  });
}

/**
 * [Obtiene el calendario de reservaciones]
 * @return {[type]}          [description]
 */
function fetchCalendar() {
  return apiClient.get('/v1/reservations/calendar');
}

export default { create, search, fetchCalendar, cancel };
