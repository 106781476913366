import { apiClient } from '../index';

/**
 * [Crea una cuota de mantenimiento]
 * @return {[type]}          [description]
 */
 function create({ concept, description, paymentYear, paymentDueDay, amount }) {
  return apiClient.post('/v1/maintenance-payments/fees', { concept, description, paymentYear, paymentDueDay, amount });
}


/**
 * [CEncuentra las cutotas de mantenimiento según los criterios de búsqueda]
 * @return {[type]}          [description]
 */
 function fetch(paymentYear, limit = 30) {
  return apiClient.get('/v1/maintenance-payments/fees', {
    params: {
      paymentYear, limit
    }
  });
}


/**
 * [Subscribe un usuario a una cuota de mantenimiento]
 * @return {[type]}          [description]
 */
 function suscribe(maintenanceFeeId, userId) {
  return apiClient.post(`/v1/maintenance-payments/fees/${maintenanceFeeId}/subscribe/user/${userId}`);
}

/**
 * [Subscribe varios usuarios a una cuota de mantenimiento]
 * @return {[type]}          [description]
 */
 function suscribeBatch(maintenanceFeeId, userIds) {
  return apiClient.post(`/v1/maintenance-payments/fees/${maintenanceFeeId}/subscribe/users`, { userIds });
}

/**
 * [Encuentra todos los usuarios suscritos a un plan de mantenimiento]
 * @return {[type]}          [description]
 */
 function usersSuscribed(maintenanceFeeId, limit = 500) {
  return apiClient.get('/v1/maintenance-payments/suscribed/users', { params: {
    maintenanceFeeId, limit
  } });
}


/**
 * [Encuentra los pagos de manteniemto según los criterios de búsqueda]
 * @return {[type]}          [description]
 */
 function searchPayment(status, customerId, maintenanceFeeId, startDate, endDate, limit = 100) {
  return apiClient.get('/v1/maintenance-payments/search', { params: {
    status, customerId, maintenanceFeeId, limit, startDate, endDate,
  } });
}

/**
 * [Acredita el pago de una cuota de mantenimiento pendiente]
 * @return {[type]}          [description]
 */
 function payByIdPayment(id) {
  return apiClient.patch(`/v1/maintenance-payments/${id}/pay`);
}


/**
 * [Cancela los recargos de un pago de mantenimiento pendiente]
 * @return {[type]}          [description]
 */
 function cancelSurchargesByIdPayment(id) {
  return apiClient.patch(`/v1/maintenance-payments/${id}/surcharges/cancel`);
}


/**
 * [Encuentra todos los deudores de cutotas de mantenimiento]
 * @return {[type]}          [description]
 */
 function fetchDebtors() {
  return apiClient.get('/v1/maintenance-payments/debtors');
}

/**
 * [Devuelve los pagos de un plan de pagos a cuotas mantenimiento]
 * @return {[type]}          [description]
 */
 function fetchByPlan(id) {
  return apiClient.get(`/v1/maintenance-payments/feesbyplanid/${id}`);
}

/**
 * [Devuelve los planes de cuotas mantenimiento a los que esta suscrito un usuario]
 * @return {[type]}          [description]
 */
function fetchUserSuscriptions (userId) {
  return apiClient.get(`/v1/maintenance-payments/userMaintPlans/${userId}`);
}


export default {
  create,
  fetch,
  suscribe,
  suscribeBatch,
  searchPayment,
  payByIdPayment,
  cancelSurchargesByIdPayment,
  fetchDebtors,
  usersSuscribed,
  fetchByPlan,
  fetchUserSuscriptions
}