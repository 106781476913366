<template>
  <div>
    <v-row>
      <v-btn color="primary" text @click="changeAutomaticPayments"> {{ automaticPayments ? 'cancelar pagos automaticos' : 'Activar pagos automaticos' }}</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" text :to="{name: 'cardsuser'}">Ver tarjetas</v-btn>
    </v-row>
    <br>
    <br>
    <div v-for="(textField, index) in paymentsmainten" :key="index">
      <v-card 
        style="margin-bottom: 25px"
        :color="getColorStatus(textField.paymentDueDate, textField.paymentDate)"
      >
        <v-row>
          <v-col cols="10">
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title style="font-size: 15px; font-weight: 600">{{ textField.concept }}</v-list-item-title>
                <v-list-item-subtitle> {{ textField.concept }} </v-list-item-subtitle>
                <v-list-item-subtitle> {{ $currency.format(total(textField)) }} </v-list-item-subtitle>
                <v-list-item-subtitle v-if="textField.paymentDate"> 
                  Pagado: {{ $dayjs(textField.paymentDate).format('ll') }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  Vence: {{ $dayjs(textField.paymentDueDate).format('ll') }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>

          <v-col cols="2" class="d-flex align-end flex-column">
            <v-icon 
            v-if="textField.status === 'paid'"
            x-large
            color="primary"
            style="padding-right: 15px; padding-top: 20px">
            
              mdi-checkbox-marked-circle
            </v-icon>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-btn
          small
          text
          v-if="textField.status === 'pending'"
          @click="createPayLink(textField.id)"
              color="primary"
          >
            <v-icon>
              mdi-cash
            </v-icon>
            {{ textField.paymentLink ? 'Renueva' : 'Solicitar'}} Link de pago
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
          small
            text
            color="deep-purple accent-4"
            v-if="textField.status === 'pending' && textField.paymentLink"
            :href="textField.paymentLink"
            target="_blank"
          >
            <v-icon>mdi-link</v-icon> Hacer pago
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>


<script>
import conektaService from '@/services/users/conekta';
import adminScheduleMaintenanceService from '@/services/admin/maintenancePayments';
import userService from '@/services/users';

import dayjs from 'dayjs';
import 'dayjs/locale/es';
const localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);
dayjs.locale('es');

export default {
  data() {
    return {
      automaticPayments: false,
      paymentsmainten: [],
      loading: false,
      validForGenerateModelPayment: true,
      dayjs,
      textFields: [],
      items: ['pending', 'paid', 'canceled'],
      initialConcept: '',
      initialPayment: '',
      initialDate: dayjs(Date.now()).format('YYYY-MM-DD'),
      initialPayments: [],
      optionalPayments: [],
      modelPymentData: {
        startYear: dayjs(Date.now()).format('YYYY'),
        startMonth: dayjs(Date.now()).format('MM'),
        paymentDueDay: '05',
        amount: '0',
        numberMonthlyPayments: '0',
      },
    };
  },
  mounted() {
    this.fetch();
    this.perfil();
  },
  computed: {
    existPayments() {
      return this.optionalPayments.some((item) => Object.prototype.hasOwnProperty.call(item, 'id'));
    },
    userId() {
      return this.$store.state.session.userId;
    },
  },

  methods: {
    changeAutomaticPayments() {
      conektaService.automaticPayments(!this.automaticPayments)
      .then( response => {
        this.automaticPayments = response.data.automaticPayments;
        let msg = 'se han desabilidatdo exitosamente.';
        if (this.automaticPayments) {
          msg = 'se ha habilitado exitosamente.';
        }
        this.$swal.fire({
          title: 'Pagos automaticos',
          html:  `Los pagos automaticos: ${msg}`,
          icon: 'info',
        });
      }).catch((error) => {
            if (error.response && [400, 404, 409].includes(error.response.status)) {
              this.$swal('Error al activar cargos automaticos', error.response.data.message, 'warning');
            }
          });
    },
    total(item) {
      if (item.forgiveSurcharges) {
        return item.amount + item.surcharges
      }
      return item.amount
    },
    getColorStatus(dueDate, paymentDate) {
      if (!paymentDate) {
        const date1 = this.$dayjs()
        const date2 = this.$dayjs(dueDate)
        return date1.diff(date2) < 0 ? 'white' : '#85d9f9';
      }
      return 'white';

    },
    perfil() {
      userService.user().then((response) => {
        this.automaticPayments = response.data.automaticPayments;
      });
    },
    fetch() {
      adminScheduleMaintenanceService.searchPayment('all', this.userId).then((response) => {
        this.paymentsmainten = response.data;
      });
    },
    createPayLink(id) {
      conektaService.linkMaintenance(id).then((response) => {
        this.fetch();
        this.payLink = response.data.paymentLink;
        const html = 'De click en el siguiente link para proceder a pagar en linea. <br/>' +
        'Proceder a <a href="' + this.payLink + '" target="_blank">pagar</a> ';
        this.$swal.fire({
          title: 'Se ha generado el link de pago',
          html,
          icon: 'info',
        });
      });
    },
  },
};
</script>