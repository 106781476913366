import { apiClient } from '../index';

/**
 * [Genera una serie de mensualidades]
 * @return {[type]}          [description]
 */
function generate({ startYear, startMonth, paymentDueDay, amount, numberMonthlyPayments }) {
  return apiClient.get('/v1/monthly-payments/generate', {
    params: {
      startYear,
      startMonth,
      paymentDueDay,
      amount,
      numberMonthlyPayments,
    },
  });
}

/**
 * [Crea una serie de mensualidades]
 * @return {[type]}          [description]
 */
function createPayment(customerId, monthlyPayments) {
  return apiClient.post('/v1/monthly-payments', { customerId, monthlyPayments });
}

/**
 * [Crea un pago de enganche]
 * @return {[type]}          [description]
 */
function createPaymentInitial({ customerId, concept, paymentDate, amount }) {
  return apiClient.post('/v1/monthly-payments/payment/initial', { customerId, concept, paymentDate, amount });
}

/**
 * [Obtiene los pagos de enganche de un respectivo usuario]
 * @return {[type]}          [description]
 */
function fetchPaymentInitial() {
  return apiClient.get('/v1/monthly-payments/payment/initial');
}

/**
 * [Obtiene los pagos de enganche de un respectivo usuario, solo los admin pueden solicitarlo]
 * @return {[type]}          [description]
 */
function fetchByUserIdPaymentInitial(customerId) {
  return apiClient.get('/v1/monthly-payments/payment/initial/' + customerId);
}

/**
 * [Acredita el pago de una mensualidad pendiente]
 * @param {number} id El identificador de la mensualidad
 * @return {[type]}          [description]
 */
function payByIdPayment(id) {
  return apiClient.patch(`/v1/monthly-payments/${id}/pay`);
}

/**
 * [Cancela una mensualidad]
 * @param {number} id El identificador de la mensualidad
 * @return {[type]}          [description]
 */
function cancelByIdPayment(id) {
  return apiClient.patch(`/v1/monthly-payments/${id}/cancel`);
}

/**
 * [Cancela los recargos de una mensualidad pendiente]
 * @param {number} id El identificador de la mensualidad
 * @return {[type]}          [description]
 */
function cancelSurchargesByIdPayment(id) {
  return apiClient.patch(`/v1/monthly-payments/${id}/surcharges/cancel`);
}

/**
 * [Encuentra las mensualidades según los criterios de búsqueda]
 * @param {number} customerId El identificador del usuario
 * @return {[type]}          [description]
 */
function search(customerId, status, startDate, endDate) {
  return apiClient.get('/v1/monthly-payments/search', {
    params: {
      customerId,
      status,
      startDate,
      endDate,
    },
  });
}

export default {
  generate,
  createPayment,
  createPaymentInitial,
  payByIdPayment,
  cancelByIdPayment,
  cancelSurchargesByIdPayment,
  search,
  fetchPaymentInitial,
  fetchByUserIdPaymentInitial,
};
