<template>
  <div>
    <div v-for="(textField, index) in payments" :key="index">
      <v-card
        style="margin-bottom: 25px"
        :color="getColorStatus(textField.paymentDueDate, textField.paymentDate)"
      >
        <v-row>
          <v-col cols="10">
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title style="font-size: 15px; font-weight: 600">{{ textField.concept }}</v-list-item-title>
                <v-list-item-subtitle> {{ textField.concept }} </v-list-item-subtitle>
                <v-list-item-subtitle> {{ $currency.format(total(textField)) }} </v-list-item-subtitle>
                <v-list-item-subtitle v-if="textField.paymentDate"> 
                  Pagado: {{ $dayjs(textField.paymentDate).format('ll') }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  Vence: {{ $dayjs(textField.paymentDueDate).format('ll') }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>

          <v-col cols="2" class="d-flex align-end flex-column">
            <v-icon 
            v-if="textField.status === 'paid'"
            x-large
            color="primary"
            style="padding-right: 15px; padding-top: 20px">
            
              mdi-checkbox-marked-circle
            </v-icon>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-btn
          small
          text
          v-if="textField.status === 'pending'"
          @click="createPayLink(textField.id)"
              color="primary"
          >
            <v-icon>
              mdi-cash
            </v-icon>
            {{ textField.paymentLink ? 'Renueva' : 'Crea'}} Link de pago
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
          small
            text
            color="deep-purple accent-4"
            v-if="textField.status === 'pending' && textField.paymentLink"
            :href="textField.paymentLink"
            target="_blank"
          >
            <v-icon>mdi-link</v-icon> Pagar
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>


<script>
import conektaService from '@/services/users/conekta';
import monthlyPaymentService from '@/services/admin/monthlyPayments';

export default {
  data() {
    return {
      payments: [],
      loading: false,
      textFields: [],
    };
  },
  mounted() {
    this.fetch();
  },
  computed: {
    userId() {
      return this.$store.state.session.userId;
    },
  },

  methods: {
    total(item) {
      if (item.forgiveSurcharges) {
        return item.amount + item.surcharges
      }
      return item.amount
    },
    getColorStatus(dueDate, paymentDate) {
      if (!paymentDate) {
        const date1 = this.$dayjs()
        const date2 = this.$dayjs(dueDate)
        return date1.diff(date2) < 0 ? 'white' : '#85d9f9';
      }
      return 'white';

    },
    fetch() {
      monthlyPaymentService.search(this.userId)
      .then((response) => {
        this.payments = response.data;
      });
    },
    createPayLink(id) {
      conektaService.linkMonthly(id).then((response) => {
        this.payLink = response.data.paymentLink;
        this.fetch();
        const html = 'De click en el siguiente link para proceder a pagar en linea. <br/>' +
        'Proceder a <a href="' + this.payLink + '" target="_blank">pagar</a> ';
        this.$swal.fire({
          title: 'Se ha generado el link de pago',
          html,
          icon: 'info',
        });
      });
    },
  },
};
</script>