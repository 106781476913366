<template>
  <div>
    <div style="display: flex; align-items: center; justify-content: center; padding: 50px 0px 50px 0px">
      <span class="title-user-scr-b">¿Qué pago deseas hacer?</span>
    </div>

    <v-btn block color="primary" large rounded link :to="{ name: 'maintenancefees' }">Cuotas de mantenimiento</v-btn>
    <br />

    <v-btn block color="primary" large rounded link :to="{ name: 'mortgagepayments' }">Pago de mensualidades</v-btn>
    <br />
    <v-btn block color="primary" large rounded link :to="{ name: 'eventstopay' }">Pago de reservaciones</v-btn>
    <br />

    <v-btn block color="primary" large rounded outlined link :to="{ name: 'spendinformation' }"
      >¿En qué se gasta mi dinero?</v-btn
    >
  </div>
</template>


<script>
export default {};
</script>

<style lang="scss" scoped>
@import './src/assets/style.scss';
</style>