import { apiClient } from '../index';

/**
 * [Activa/desactiva pagos automaticos]
 * @return {[type]}          [description]
 */
 function automaticPayments(automaticPayments) {
  return apiClient.patch('/v1/config/customers/payments/automatic', { automaticPayments });
}

/**
 * [obtiene la tarjeta del cliente]
 * @return {[type]}          [description]
 */
 function fetch() {
  return apiClient.get(`/v1/conekta/customers/cards`);
}

/**
 * [borra la tarjeta del cliente]
 * @return {[type]}          [description]
 */
 function deleteCard() {
  return apiClient.delete(`/v1/conekta/customers/cards`);
}

/**
 * [Renueva el enlace de pago de mensualidad pendiente]
 * @return {[type]}          [description]
 */
 function createCardToPay(cardTokenId) {
  return apiClient.post('/v1/conekta/customers/cards', {
    cardTokenId
  });
}

/**
 * [Renueva el enlace de pago de una cuota de mantenimiento pendiente]
 * @return {[type]}          [description]
 */
 function linkMaintenance(maintenanceId) {
  return apiClient.post(`/v1/conekta/maintenance-payments/${maintenanceId}/payments/links`);
}

/**
 * [Renueva el enlace de pago de mensualidad pendiente]
 * @return {[type]}          [description]
 */
 function linkMonthly(monthlyPaymentId) {
  return apiClient.post(`/v1/conekta/monthly-payments/${monthlyPaymentId}/payments/links`);
}


export default {
  linkMaintenance,
  linkMonthly,
  deleteCard,
  automaticPayments,
  fetch,
  createCardToPay,
}