<template>
  <div>
    <div v-if="!paymentsRooms.length">
      Aun no hay reservaciones
    </div>
    <div 
    v-else
    v-for="(textField, index) in paymentsRooms" :key="index">
      <v-card 
        style="margin-bottom: 25px"
        :color="getColorStatus(textField.expirationDate, textField.paymentDate)"
      >
        <v-row>
          <v-col cols="10">
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title style="font-size: 15px; font-weight: 600">{{ textField.room.title }} - {{ textField.title }}</v-list-item-title>
                <v-list-item-subtitle> {{ textField.description }} </v-list-item-subtitle>
                <v-list-item-subtitle> Total: {{ $currency.format(total(textField)) }} </v-list-item-subtitle>
                <v-list-item-subtitle>
                  Fecha de evento: {{ $dayjs(textField.reservationDate).format('ll') }} <br/>
                  Hora del evento: {{ textField.reservationHour }} Hrs <br/>
                  Fin del evento: {{ textField.reservationHour + textField.lapse }} Hrs
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="textField.paymentDate"> 
                  Pagado: {{ $dayjs(textField.paymentDate).format('ll') }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  Vence: {{ $dayjs(textField.expirationDate).format('ll') }} <br/>
                  Fecha de solicitud: {{ $dayjs(textField.createdAt).format('ll') }}
                </v-list-item-subtitle>
                <v-list-item-subtitle> Estado del pago: {{ textField.statusPayment }} </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>

          <v-col cols="2" class="d-flex align-end flex-column">
            <v-icon 
            v-if="textField.status === 'paid'"
            x-large
            color="primary"
            style="padding-right: 15px; padding-top: 20px">
            
              mdi-checkbox-marked-circle
            </v-icon>
          </v-col>
        </v-row>
        <v-card-actions v-if="textField.status === 'pending'">
          Solicitud en espera de aprobación
        </v-card-actions>
        <v-card-actions v-else>
          <v-btn
          small
          text
          v-if="!depositWasPaid(textField)"
          :href="textField.depositPaymentLink"
            target="_blank"
              color="primary"
          >
            <v-icon>
              mdi-cash
            </v-icon>
            Link de pago deposito
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
          v-if="depositWasPaid(textField) && !textField.paymentValidated"
          small
            text
            color="deep-purple accent-4"
            :href="textField.paymentLink"
            target="_blank"
          >
            <v-icon>mdi-link</v-icon> Pagar complemento
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>


<script>
import usersReservationsService from '@/services/users/reservations';

import dayjs from 'dayjs';
import 'dayjs/locale/es';
const localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);
dayjs.locale('es');

export default {
  data() {
    return {
      paymentsRooms: [],
      loading: false,
      validForGenerateModelPayment: true,
      dayjs,
      textFields: [],
      items: ['pending', 'paid', 'canceled'],
      initialConcept: '',
      initialPayment: '',
      initialDate: dayjs(Date.now()).format('YYYY-MM-DD'),
      initialPayments: [],
      optionalPayments: [],
      modelPymentData: {
        startYear: dayjs(Date.now()).format('YYYY'),
        startMonth: dayjs(Date.now()).format('MM'),
        paymentDueDay: '05',
        amount: '0',
        numberMonthlyPayments: '0',
      },
    };
  },
  mounted() {
    this.fetch();
  },
  computed: {
    existPayments() {
      return this.optionalPayments.some((item) => Object.prototype.hasOwnProperty.call(item, 'id'));
    },
    userId() {
      return this.$store.state.session.userId;
    },
  },

  methods: {
    depositWasPaid(event) {
      if (!event.room.deposit) {
        return true;
      }
      return event.depositPaid;
    },
    total(item) {
      if (item.room.deposit) {
        return item.room.costAmount + item.room.depositAmount
      }
      return item.costAmount
    },
    getColorStatus(dueDate, paymentDate) {
      if (!paymentDate) {
        const date1 = this.$dayjs()
        const date2 = this.$dayjs(dueDate)
        return date1.diff(date2) < 0 ? 'white' : '#85d9f9';
      }
      return 'white';

    },
    fetch() {
      usersReservationsService.search(null, this.userId).then((response) => {
        this.paymentsRooms = response.data;
      });
    },
  },
};
</script>